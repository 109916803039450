export const validateExtensions = (file, extensions) => {
    const { name } = file;
    let valid = false;
    for (let j = 0; j < extensions.length; j += 1) {
        const extension = extensions[j]
        if (name.substr(name.length - extension.length, extension.length).toLowerCase() === extension.toLowerCase()) {
            valid = true;
            break;
        }
    }
    return valid;
}

export const validateFileSize = (file, maxSize) => file.size <= maxSize
