import flatpickr from "flatpickr"
import { Russian } from "flatpickr/dist/l10n/ru.js"

flatpickr('.js-date', {
    "locale": Russian,
		dateFormat: "d-m-Y",
		defaultDate: ["today"]
});

flatpickr('.js-dateRange', {
    "locale": Russian,
		mode: "range",
		dateFormat: "d-m-Y",
		defaultDate: [new Date().fp_incr(-30), "today"]
});
