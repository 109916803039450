import $ from 'jquery'
import svg4everybody from 'svg4everybody'
import Vivus from './vendor/_visible'
import './vendor/_vivus.min'
import './vendor/_jquery-validation'
import './vendor/_formstyler'
import './vendor/_masked-input'
import './vendor/_formstyler'
import './vendor/_tooltipster'
import './vendor/_simplebar'
import './vendor/_hammer.min.js';
import './vendor/_circle-progress.js';
import './vendor/_filepond.js';

svg4everybody()

window.$ = $
window.jQuery = $
