$(document).ready(() => {
  if ( $('.form-input input').length ) {
    checkActiveInput($('.form-input input'));
  }
  if ( $('.form-input textarea').length ) {
    checkActiveInput($('.form-input textarea'));
  }
})

function checkActiveInput(input) {
  input.each((index, el) => {
    ($(el).val().length === 0)
      ?
      $(el).siblings('.form__label').removeClass('active')
      :
      $(el).siblings('.form__label').addClass('active');
  })
}

$('.form-input input, .form-input textarea').on('change, blur, keyup', function() {
  let parent = $(this).parent();
  let label = parent.find('.form__label');

  ($(this).val().length === 0)
    ?
    label.removeClass('active')
    :
    label.addClass('active');
})

$('.js-date').on('click', function() {
  let parent = $(this).parent();
  let label = parent.find('.form__label');

  ($(this).val().length === 0)
    ?
    label.removeClass('active')
    :
    label.addClass('active');
})
