import magnificPopup from 'magnific-popup';
const html = $('html');
const body = $('body');

function modalOpen(el, src, type){
  let otherCards;
  $.magnificPopup.open({
    items: {
      src: src,
      type: type
    },
    mainClass: 'mfp-move-from-top',
    closeBtnInside: false,
    fixedContentPos: false,
    removalDelay: 500, //delay removal by X to allow out-animation
    callbacks: {
      beforeOpen: function() {
        html.addClass('no-margin');
      },
      afterClose: function() {
        html.removeClass('no-margin');
        if (otherCards !== undefined) otherCards.destroy(true, true);
      },
			// open: function () {
      //   if (el.hasClass('js-otherCards')) {
      //     const otherCards = new Swiper(".js-otherCards", {
      // 			loop: true,
      // 			slidesPerView: 1,
      // 			loop: true,
      // 			spaceBetween: 30,
      // 			touchRatio: 0.3,
      // 			navigation: {
      // 			  nextEl: '.swiper-button-next',
      // 			  prevEl: '.swiper-button-prev',
      // 			},
      // 			breakpoints : {
      // 				768: {
      // 					slidesPerView: 3
      // 				}
      // 			}
      // 		});
      //   }
			// }
    },
    midClick: true
  });
}

$(document).on('click', '.js-modal', function(event) {
    const el = $(this);
    const src = $(this).data('mfp-src');
    const type = $(this).data('mfp-ajax') || 'inline';
    event.preventDefault();

    modalOpen(el, src, type);
});

$(document).on('click', '.js-modalClose', (event) => {
	event.preventDefault();
	$.magnificPopup.close();
});
