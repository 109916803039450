import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

const mainSlider = new Swiper(".js-mainSlider", {
	loop: true,
  slidesPerView: 1,
	loop: true,
	loopedSlides: 3,
	touchRatio: 0.3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
    pagination: {
    el: '.swiper-pagination',
  },
});
