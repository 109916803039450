import Swiper from 'swiper';
import SwiperCore, { Navigation, EffectFade } from 'swiper/core';
SwiperCore.use([Navigation, EffectFade]);

const slider = new Swiper(".js-slider", {
	loop: true,
  slidesPerView: 1,
	// autoHeight: true,
	calculateHeight:true,
	spaceBetween: 50,
	effect: "fade",
	touchRatio: 0.3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});
