const $mainMenu = $(".top-nav");
const $autoNav = $(".header-nav");
const $autoNavMore = $(".top-nav-more");
const $autoNavMoreList = $(".top-nav-more__list");

autoNavMore = () => {
  let childNumber = 2;

  if ($(window).width() >= 320) {

    const $menuWidth = $mainMenu.width();
    const $autoNavWidth = $autoNav.width();
    if ($autoNavWidth > $menuWidth) {
      $autoNav
        .children(`li:nth-last-child(${childNumber})`)
        .prependTo($autoNavMoreList);
      autoNavMore();
    } else {
      // CODE FIRES WHEN WINDOW SIZE GOES UP
      const $autoNavMoreFirst = $autoNavMoreList
      .children("li:first-child")
      .width();
      // CHECK IF ITEM HAS ENOUGH SPACE TO PLACE IN MENU
      if ($autoNavWidth + $autoNavMoreFirst < $menuWidth) {
        $autoNavMoreList.children("li:first-child").insertBefore($autoNavMore);
      }
    }
    if ($autoNavMoreList.children().length > 0) {
      $autoNavMore.show();
      childNumber = 2;
    } else {
      $autoNavMore.hide();
      childNumber = 1;
    }
  }
};


autoNavMore();
$(window).resize(autoNavMore);
