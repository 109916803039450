$(document).ready(function() {
	let progress = $('.js-progress');
	if (progress.length) {
		progress.each( function(index) {
			let step = $(this).data('step');
			let steps = $(this).data('steps');
			$(progress[index]).circleProgress({
				max: steps,
				value: step
			});
		});
	}
})
