import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
const bodyScrollLock = require('body-scroll-lock');
const menu = document.querySelector('.header-logo-col');
const body = $('body');

$(document).on('click', '.js-menu:not(.active)', function() {
  openMenu();
})

$(document).on('click', '.js-menu.active, .js-filter', function() {
  closeMenu();
})

$(document).on('keyup', (e)=> {
  if ( body.hasClass('menu-on') && (e.keyCode === 27) ) {
    closeMenu();
  }
});

if ( $(window).width() < 1025 ) {
  const hammerBody = Hammer(document.body);
  hammerBody.get('swipe').set({ threshold: 150 });
  hammerBody.get('swipe').set({ threshold: 150 });
  hammerBody.on("swipeleft" ,function(e){
    if (!e.target.closest('.swiper-container')) {
      openMenu();
    }
  });
  hammerBody.on("panright" ,function() { // because of js-bar
    closeMenu();
  });
}

function openMenu() {
  body.addClass('menu-on');
  body.removeClass('search-on');
  body.removeClass('city-on');
  $('.js-menu').addClass('active');
  bodyScrollLock.disableBodyScroll(menu);
}

function closeMenu() {
  body.removeClass('menu-on');
  $('.js-menu').removeClass('active');
  bodyScrollLock.enableBodyScroll(menu);
}
