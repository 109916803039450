import Swiper from 'swiper';
import SwiperCore, { Navigation } from 'swiper/core';
SwiperCore.use([Navigation]);

const sliderBonuses = new Swiper(".js-sliderBonuses", {
	loop: true,
  slidesPerView: 4,
	spaceBetween: 30,
	touchRatio: 0.3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
	breakpoints: {
		300: {
			slidesPerView: 1
		},
		768: {
			slidesPerView: 2
		},
		1024: {
			slidesPerView: 4
		},
	}
});
