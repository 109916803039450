import './vendor'

import './components/_main-nav'
import './components/_menu'
import './components/_category-icons'
import './components/_statistics'
import './components/_main-slider'
import './components/_tabs'
import './components/_tips'
import './components/_simplebar'
import './components/_modal'
import './components/_often-all'
import './components/_validate'
import './components/_show-password'
import './components/_form-input'
import './components/_formstyler'
import './components/_notices'
import './components/_user-nav'
import './components/_partner-reviews'
import './components/_inside-slider'
import './components/_bonuses-slider'
import './components/_faq'
import './components/_article-nav'
import './components/_scroll'
import './components/_widget'
import './components/_filter'
import './components/_datepicker'
import './components/_other-cards'
import './components/_mask'
import './components/_circle-progress'
import './components/_upload'
import './components/_only-digits'
import './components/_avatar-load'
import './components/_form-user'
