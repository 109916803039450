$(document).ready(function() {
	$(document).on('click', '.js-scroll a', function (event) {
		event.preventDefault();
  		if (this.hash !== "" && $(this.hash).length > 0) {
        var hash = this.hash;
        setTimeout(function () {
          $('html, body').animate({scrollTop: $(hash).offset().top - 90}, 1000);
          window.location.hash = hash;
        }, 100);
        return false;
      } else {
  			window.location.href = $(this).attr('href');
  		}
    });
  });

var myHash = location.hash;
if(myHash[1] != undefined) {
	if ($(myHash).length > 0) {
		$('html, body').animate({scrollTop: $(myHash).offset().top - 90}, 1500);
	}
}
