$(document).ready( function() {
	$('.js-tabsList').on('click', 'li:not(.active)', function() {
		const _this = $(this);
		const currentTab = _this.parents('.js-tabs').find('.tab').eq(_this.index());

		_this.addClass('active').siblings().removeClass('active');
		currentTab.siblings('.tab').hide();
		currentTab.fadeIn(750);

	})
});

$(document).on('click','.js-tabsList li', function() {
	var listWidth = $(this).parent().width();
	var contentWidth = $(this).closest('.simplebar-content').width();
	var tab = $(this);
	var fix = tab.width() / 2;
	var offset = ($(this).offset().left - $(this).parent().offset().left) - fix;

	$(this).closest(".simplebar-content-wrapper").animate({ scrollLeft: offset});

	// bam(offset);
});

function bam(offset) {
}
