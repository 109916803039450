import Swiper from 'swiper';
import SwiperCore, { Navigation } from 'swiper/core';
SwiperCore.use([Navigation]);

const partnerReviews = new Swiper(".js-partnerReviews", {
	loop: true,
  slidesPerView: 1,
	loop: true,
	spaceBetween: 30,
	loopedSlides: 3,
	touchRatio: 0.3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
	breakpoints : {
		768: {
			slidesPerView: 2
		}
	}
});
