$('.js-upload').filepond();
const labels_RU = {
    labelIdle: 'Перетащите или выберите <span class="filepond--label-action"> файл </span>',
    labelInvalidField: 'Поле содержит недопустимые файлы',
    labelFileWaitingForSize: 'Укажите размер',
    labelFileSizeNotAvailable: 'Размер не поддерживается',
    labelFileLoading: 'Ожидание',
    labelFileLoadError: 'Ошибка при ожидании',
    labelFileProcessing: 'Загрузка',
    labelFileProcessingComplete: 'Загрузка завершена',
    labelFileProcessingAborted: 'Загрузка отменена',
    labelFileProcessingError: 'Ошибка при загрузке',
    labelFileProcessingRevertError: 'Ошибка при возврате',
    labelFileRemoveError: 'Ошибка при удалении',
    labelTapToCancel: 'нажмите для отмены',
    labelTapToRetry: 'нажмите, чтобы повторить попытку',
    labelTapToUndo: 'нажмите для отмены последнего действия',
    labelButtonRemoveItem: 'Удалить',
    labelButtonAbortItemLoad: 'Прекращено',
    labelButtonRetryItemLoad: 'Повторите попытку',
    labelButtonAbortItemProcessing: 'Отмена',
    labelButtonUndoItemProcessing: 'Отмена последнего действия',
    labelButtonRetryItemProcessing: 'Повторите попытку',
    labelButtonProcessItem: 'Загрузка',
    labelMaxFileSizeExceeded: 'Файл слишком большой',
    labelMaxFileSize: 'Максимальный размер файла: {filesize}',
    labelMaxTotalFileSizeExceeded: 'Превышен максимальный размер',
    labelMaxTotalFileSize: 'Максимальный размер файла: {filesize}',
    labelFileTypeNotAllowed: 'Файл неверного типа',
    fileValidateTypeLabelExpectedTypes: 'Tipos de arquivo suportados são {allButLastType} ou {lastType}',
    imageValidateSizeLabelFormatError: 'Тип изображения не поддерживается',
    imageValidateSizeLabelImageSizeTooSmall: 'Изображение слишком маленькое',
    imageValidateSizeLabelImageSizeTooBig: 'Изображение слишком большое',
    imageValidateSizeLabelExpectedMinSize: 'Минимальный размер: {minWidth} × {minHeight}',
    imageValidateSizeLabelExpectedMaxSize: 'Максимальный размер: {maxWidth} × {maxHeight}',
    imageValidateSizeLabelImageResolutionTooLow: 'Разрешение слишком низкое',
    imageValidateSizeLabelImageResolutionTooHigh: 'Разрешение слишком высокое',
    imageValidateSizeLabelExpectedMinResolution: 'Минимальное разрешение: {minResolution}',
    imageValidateSizeLabelExpectedMaxResolution: 'Максимальное разрешение: {maxResolution}'
}

FilePond.setOptions(labels_RU);
