import {
    getFileSizeString,
    getFileExtension,
    validateExtensions,
    validateFileSize,
 } from './_helpers';

const errorText = {
    maxsize: 'Превышен лимит размера файла 10 МБ',
    type: 'Ошибка. Неверный формат файла.',
}
const extensions = ['.jpg', '.jpeg', '.png'];
const maxFileSize = 10000000;


if ($('#avatar').length) {
    var inputAvatar = document.querySelector("#avatar");
    inputAvatar.addEventListener("change", DataFileInput );

    function DataFileInput(e) {
        var fileName = e.target.files[0].name;
        var file = e.target.files[0];
        var fileId = e.target.id;
        var fileInput = $('#avatar');
        var selectFileParent = document.querySelector("#" + fileId).parentNode;
        var SelectFileText = selectFileParent.querySelector('.inputAvatar__info');
        var SelectFilePreview = document.querySelector(".inputAvatar__preview");
        var avatarErrorText = $('.js-avatarErrorText');
        console.log(avatarErrorText);

        if(validateExtensions(file, extensions) && validateFileSize(file, maxFileSize)){
            previewFile(e);
            avatarErrorText.removeClass('is_error');
            fileInput.valid();
        } else {
            if(!validateFileSize(file, maxFileSize)){
                avatarErrorText.text(errorText.maxsize);
            } else {
                avatarErrorText.text(errorText.type);
            }
            avatarErrorText.addClass('is_error');
        }
    }
}

function previewFile(e) {
    var preview = document.querySelector('.inputAvatar__preview img');
    var file = e.target.files[0];
    var reader  = new FileReader();
    var avatarLabel = $('.inputAvatar__label');
    var avatarTitle = $('.inputAvatar__title');
    reader.onloadend = function () {
    preview.src = reader.result;
  }

  if (file) {
    reader.readAsDataURL(file);
  } else {
    preview.src = "";
  }
  avatarLabel.addClass('uploaded');
  avatarTitle.html('Заменить фото');
}
