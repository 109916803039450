$.extend($.validator.messages, {
	required: "Заполните поле",
	remote: "Введите правильное значение",
	email: "Введите корректную электронную почту",
	url: "Введите корректный URL",
	date: "Введите корректную дату",
	dateISO: "Введите корректную дату в формате ISO",
	number: "Введите число",
	digits: "Вводите только цифры",
	letters: "Вводите только буквы",
	creditcard: "Введите правильный номер кредитной карты",
	equalTo: "Пароли не совпадают",
	extension: "Выберите файл с правильным расширением",
	maxlength: $.validator.format( "Максимальная длина описания - {0} символов" ),
	minlength: $.validator.format( "Введите не меньше {0} символов" ),
	rangelength: $.validator.format( "Введите значение длиной от {0} до {1} символов" ),
	range: $.validator.format( "Введите сумму от {0} до {1}" ),
	max: $.validator.format( "Максимальная длина описания - {0} символов" ),
	min: $.validator.format( "Введите число, большее или равное {0}" ),
});

function initValidate() {
	$('.js-validate').each((index, form) => {
	$(form).find('[type="submit"]').prop('disabled', true);

		$(form).validate({
			ignore: [],
			errorElement: 'span',
			errorClass: 'label-error',
			rules : {
				'password_confirm' : {
					equalTo : '.js-password',
					minlength: 8
				},
				'reg_passConfirm' : {
					equalTo : '.js-confirm',
					minlength: 8
				},
			},
			highlight: function(element) {
				setTimeout(() => {
					if(!$(element).attr('disabled')){
						$(element).parent().removeClass('is-success');
						$(element).parent().addClass('is-error');

						if($(element).parent('.jq-checkbox')) {
							const checkboxParent = $(element).parents('label');
							checkboxParent.append(checkboxParent.find('.label-error'));
						}

						if($(element).attr('type') == 'checkbox' || $(element).hasClass('select') || $(element).attr('type') == 'radio'){
							const parent = $(element).parent();

							parent.append(parent.find('.label-error'));
						}
					}
				});
			},
			unhighlight: function(element) {
				setTimeout(() => {
					if(!$(element).attr('disabled')){
						if($(element).val() != '') $(element).parent().addClass('is-success');
						$(element).parent().removeClass('is-error');
					}
				});
			}
		});

		if ( $(form).validate().checkForm() ) {
			$(form).find('[type="submit"]').prop('disabled', false)
		} else {
			$(form).find('[type="submit"]').prop('disabled', false)
		}

		$('input', form).on('change', () => {
			if ( $(form).validate().checkForm() ) {
				$(form).find('[type="submit"]').prop('disabled', false)
			} else {
				$(form).find('[type="submit"]').prop('disabled', false)
			}
		})
	});
}

$(document).on('initValidateTrigger', () => {
	initValidate()
});

$(document).trigger('initValidateTrigger');

$(document).on('change input blur', '.form-input', (event) => {
	const _this = $(event.currentTarget);
	if(_this.val() == '') _this.parent().removeClass('is-success');
});

$(document).on('change input blur', '.form-textarea textarea', (event) => {
	const _this = $(event.currentTarget);
	_this.valid();
});

$(document).on('change', '.jq-checkbox input', (event) => {
	const _this = $(event.currentTarget);
	_this.valid();
});

$.validator.addMethod("validpassword", function(value, element) {
	if(value.match(/[A-z]/) && !value.match(/[А-я]/) && value.match(/[A-Z]/)) return true;

}, "Пароль должен содержать от 8 символов, латинские символы, символы верхнего регистра");

/*
 * Валидация пароля
 */

// $.validator.addMethod('passwordcustom', function(value, element) {
// 	if(value.match(/[A-z]/) && !value.match(/[А-я]/) && value.match(/[0-9]/)) return true;

// }, 'Неверный формат пароля');

$.validator.addClassRules('js-passwordValidate', {
	validpassword: true
});

$('.js-passwordValidate').on('focus', function() {
	if ($('.js-passwordValidate').val() == '') {
		$('.js-passwordValidate').parent().addClass('is-error');
	}
});

$.validator.addMethod('phonecustom', function(value, element) {
	if(value == '+7 (___) ___-__-__') return true;
	return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/);
}, 'Указан неверный формат телефона');

$.validator.addClassRules('js-phone', {
	phonecustom: true
});

$.validator.addMethod('emailcustom', function (value, element) {
	return value.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9A-Яа-я_\-\.])+\.([A-Za-zA-Яа-я_]{2,4})$/);
}, 'Введите корректный email');

$.validator.addClassRules('js-email', {
	emailcustom: true
});

$.validator.addMethod('onlyletters', function (value, element) {
	return value.match(/^[a-zа-яA-ZА-ЯёЁ\s]+$/);
}, 'Это поле может содержать только буквы');

$.validator.addClassRules('js-letters', {
	onlyletters: true
});
