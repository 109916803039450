import Swiper from 'swiper';
import SwiperCore, { Navigation } from 'swiper/core';
SwiperCore.use([Navigation]);

const otherCards = new Swiper(".js-otherCards", {
  loop: true,
  slidesPerView: 1,
  loop: true,
  spaceBetween: 30,
  touchRatio: 0.3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints : {
    768: {
      slidesPerView: 3
    }
  }
});
