let maskPlaceholder = '?+7 (999) 999-99-99';

$('.js-mask').each((index, element) => {
	var _this = $(element);

	if(_this.data('mask')){
		maskPlaceholder = _this.data('mask');
	}

	_this.mask(maskPlaceholder);
});

$('.js-passport').mask('9999-999999');

$('.js-department').mask('999-999');

$('.js-inn').mask('999999999999');

$('.js-code').mask('9');

$('.js-mask').on('blur change', (event) => {
	var _this = $(event.currentTarget);

	setTimeout(() => { _this.valid(); }, 100);
});

$(document).on('keydown', '.js-mask', function(event){
	if(event.which === 32) return false;
});
