$(document).on('click', '.js-showPassword', function () {
	let _this = $(this);

	_this.toggleClass('is-active');
	_this.parent().toggleClass('visible');

	if (_this.parent('.form-input-password').hasClass('active')) {
		_this.parent('.form-input-password').find('input').attr('type', 'password');
		_this.parent('.form-input-password').removeClass('active');

	} else {
		_this.parent('.form-input-password').addClass('active');
		_this.parent('.form-input-password').find('input').attr('type', 'text');
	}
});
