$('.js-widget').on('click', function() {
  $(this).closest('.wgt').toggleClass('active').find('.wgt-content');
});

$(document).on('click', '.js-widgetReset', function(e) {
  let ch = $(this).closest('.wgt').find('input:checkbox');
  ch.removeAttr('checked');
  setTimeout(function() {
    ch.trigger('refresh');
  }, 1)
});

$(document).on('click', '.wgt__sublist label', function() {
  let label = $(this);
});
