$('input[type="radio"], input[type="checkbox"]').styler();

$('.js-select').styler({
  selectVisibleOptions: 6,
  singleSelectzIndex: 10,
  selectSmartPositioning: false,
  onSelectOpened: function () {
    $(this).closest('.form-select').addClass('higher');
  },
  onSelectClosed: function(){
    $(this).closest('.form-select').removeClass('higher');
  }
})

$('.js-selectSmall').styler({
  selectVisibleOptions: 3,
  singleSelectzIndex: 10,
  selectSmartPositioning: false,
  onSelectOpened: function () {
    $(this).closest('.form-select').addClass('higher');
  },
  onSelectClosed: function(){
    $(this).closest('.form-select').removeClass('higher');
  }  
})
