import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
const bodyScrollLock = require('body-scroll-lock');
const filter = document.querySelector('.filter-col');
const body = $('body');

$('.js-filter').on('click', ()=> {
  body.addClass('filter-on');
  body.removeClass('menu-on');
  body.removeClass('search-on');
  body.removeClass('city-on');
  $('.js-filter').addClass('active');
  bodyScrollLock.disableBodyScroll(filter);
})

$('.js-filterClose').on('click', ()=> {
  body.removeClass('filter-on');
  $('.js-filter').removeClass('active');
  bodyScrollLock.enableBodyScroll(filter);
})
//
// $(document).on('keyup', (e)=> {
//   if ( body.hasClass('filter-on') && (e.keyCode === 27) ) {
//     closeMenu();
//   }
// });
